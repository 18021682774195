/* prettier-ignore */
@tailwind base;

/* prettier-ignore */
@tailwind components;

/* prettier-ignore */
@tailwind utilities;

/* TODO: Consider this

Always use prefix toolio-

.toolio-h1 {
  @apply text-2xl font-bold;
}
*/

.toolio-tree-root {
  height: 100%;
}

.toolio-tree-dragging-source {
  opacity: 0.3;
}

.toolio-tree-drop-target {
  background-color: #e8f0fe;
}

.toolio-form-error-label {
  font-size: 12px;
  line-height: 1.2;
  color: #fa5252;
  word-break: break-word;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
}

.PhoneInput {
  height: 36px;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
}

.PhoneInput--focus {
  border: 1px solid #38298b;
}

.PhoneInput--error {
  border: 1px solid #bf0d38;
}

.PhoneInput--error.PhoneInput--focus {
  border: 1px solid #38298b;
}

.PhoneInputInput {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  font-size: 15px;
  line-height: 20px;
  font-family: 'Rubik', sans-serif;
  color: #4d4d4d;
  padding: 8px;
}

.PhoneInput--error .PhoneInputInput {
  color: #bf0d38;
}

.PhoneInputInput::placeholder {
  color: #b3b3b3;
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  line-height: 20px;
}

.PhoneInputInput:focus-visible {
  outline: none;
}

.PhoneInputCountrySelectArrow {
  margin-left: 8px !important;
  border-color: #4d4d4d !important;
}

.PhoneInputCountry {
  padding-top: 9px;
  padding-left: 8px;
  padding-right: 4px;
  padding-bottom: 9px;
  background-color: #e6e6e6;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.toolio-advanced-search-highlight {
  color: #b3b3b3;
  background-color: transparent;
  font-weight: 500;
}

.toolio-preloader {
  stroke: #38298b;
}

.toolio-preloader circle {
  stroke: #cecdff;
  stroke-opacity: 1;
}

.toolio-preloader g {
  stroke-width: 3;
}

.toolio-preloader-inverse {
  stroke: #fff;
}

.toolio-preloader-inverse circle {
  stroke: #5351ce;
  stroke-opacity: 1;
}

.toolio-preloader-inverse g {
  stroke-width: 3;
}

.toolio-login-background {
  background-image: url('assets/images/login-background.svg'), url('assets/images/login-background-noise.png');
  background-size: cover, auto;
  background-position: center, left top;
  background-repeat: no-repeat, repeat;
}
